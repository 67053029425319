
import Home from './Pages/Home.vue';
import Team from './Pages/Team.vue';
import MedicalHealth from './Pages/MedicalHealth.vue';
import Engineering from './Pages/Engineering.vue';
import Contact from './Pages/Contact.vue';
import MissionVision from './Pages/MissionVision.vue';
import TeamGordon from './Pages/TeamGordon.vue';

const routes = [
    { path: '/', component: Home },
    { path: '/team', component: Team },
    { path: '/mission', component: MissionVision },
    { path: '/medical', component: MedicalHealth },
    { path: '/engineering', component: Engineering },
    { path: '/contact', component: Contact },
    { path: '/team/gordon', component: TeamGordon },
];

export default routes;



