<template>
  <div class="hello">
    <Header />
    <div class="main-wrapper ">
      <section class="page-title bg-1">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <h1 class="text-capitalize mb-4 text-lg">Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="contact-form-wrap section">
        <div class="container">
          <div class="google-map mx-auto">
            <iframe
              src="https://www.google.com/maps/embed?pb=!4v1544897777080!6m8!1m7!1sTbuSFe2lNuvPCTf9iuZ7Xg!2m2!1d32.81447616683883!2d-96.80191284581062!3f101.200959541651!4f32.3268901896912!5f0.4000000000000002"
              width="100%"
              height="600"
              frameborder="0"
              style="border:0"
              allowfullscreen
            ></iframe>
          </div>
          <div class="row mt-5">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <form
                id="contact-form"
                class="contact__form"
                method="post"
                action="#"
              >
                <!-- form message -->
                <div class="row">
                  <div class="col-12">
                    <div
                      class="alert alert-success contact__msg"
                      style="display: none"
                      role="alert"
                    >
                      Your message was sent successfully.
                    </div>
                  </div>
                </div>
                <!-- end message -->
                <span class="text-color">Send a message</span>
                <h3 class="text-md mb-4">Contact Form</h3>
                <div class="form-group">
                  <input
                    name="name"
                    type="text"
                    class="form-control"
                    placeholder="Your Name"
                  />
                </div>
                <div class="form-group">
                  <input
                    name="email"
                    type="email"
                    class="form-control"
                    placeholder="Email Address"
                  />
                </div>
                <div class="form-group-2 mb-4">
                  <textarea
                    name="message"
                    class="form-control"
                    rows="4"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <button class="btn btn-main" name="submit">Send Message</button>
              </form>
            </div>

            <div class="col-lg-5 col-sm-12">
              <div class="contact-content pl-lg-5 mt-5 mt-lg-0">
                <span class="text-muted">We are Professionals</span>
                <h2 class="mb-5 mt-2">
                  Don’t Hesitate to contact with us for any kind of information
                </h2>

                <ul class="address-block list-unstyled">
                  <li>
                    <i class="ti-direction mr-3"></i>3838 Oak Lawn, Ave Ste
                    1000, Dallas TX 75219   
                  </li>

                  <li>
                    <i class="ti-email mr-3"></i>Email:
                    jay.rao@envisionequitygroup.com
                  </li>
                  <!-- <li>
                            <i class="ti-mobile mr-3"></i>Phone:+88 01672 506 744
                        </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr />

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "Contact",
  components: {
    Header,
    Footer,
  },
  props: {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.shadow {
  box-shadow: 0 0 58px 0 rgb(0 0 0 / 15%);
  transition: box-shadow 0.2s cubic-bezier(0.45, 0, 0.55, 1);
}
</style>
