<template>
  <div class="hello">
    <header class="navigation">
      <nav class="navbar navbar-expand-lg  py-0" id="navbar">
        <div class="container">
          <router-link class="navbar-brand" to="/">
            <img width="200px" src="assets/images/logo.png" />
          </router-link>
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExample09"
            aria-controls="navbarsExample09"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="fa fa-bars"></span>
          </button>
          <div
            class="collapse navbar-collapse text-center"
            id="navbarsExample09"
          >
            <ul class="navbar-nav ml-auto">
              <li
                v-for="link in links"
                :key="link.title"
                class="nav-item active"
              >
                <router-link class="nav-link" :to="link.to">{{
                  link.title
                }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {},
  data: function() {
    return {
      links: [
        { to: "/", title: "Home" },
        { to: "/team", title: "Team" },
        { to: "/mission", title: "Vision & Mission" },
        { to: "/medical", title: "Medical & Health" },
        { to: "/engineering", title: "Manufacturing & Engineering" },
        { to: "/contact", title: "Contact Us" },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
