<template>
  <div class="hello">
    <Header />
    <div class="main-wrapper ">
      <section class="page-title bg-1">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <h1 class="text-capitalize mb-4 text-lg">
                  Manufacturing & Engineering
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Section About Start -->
      <section class="section about-2 position-relative">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="about-item-img">
                <img
                  src="assets/images/engineering.jpeg"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="about-item pr-3 mb-5 mb-lg-0">
                <span class="h2 text-color">Manufacturing & Engineering</span>
                <h5 class="mt-3 mb-2 position-relative">
                  Engineering industry pulls in over $200 billion in revenue and
                  grows at a rate of 7% annually. Envision Equity Group is
                  interested in acquiring manufacturing and engineering
                  companies.
                </h5>
                <p class="mb-4">
                  Manufacturing is still a vital and thriving industry in the
                  United States. In fact, we still rank as the second top
                  manufacturing country in the world. In fact, manufacturing is
                  one of America’s largest industry sectors.
                  <br />
                  It adds over $6 trillion annually to the national economy and
                  overshadows industries such as Government, Business Services,
                  and even Real Estate.
                </p>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="about-item pr-3 mb-5 mb-lg-0">
                <p class="mb-5">
                  The U.S. manufacturing industry employed 12.4 million people
                  in March 2018, accounting for 8.5 percent of the workforce and
                  generating output (nominal GDP) of $2.2 trillion in Q3 2016,
                  with real GDP of $1.9 trillion.
                  <br />
                  Over the past 25 years, U.S.-manufactured goods exports have
                  quadrupled. In 1990, for example, U.S. manufacturers exported
                  $329.5 billion in goods. By 2000, that number had more than
                  doubled to $708.0 billion. In 2014, it reached an all-time
                  high, for the fifth consecutive year, of $1.403 trillion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "Engineering",
  components: {
    Header,
    Footer,
  },
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.shadow {
  box-shadow: 0 0 58px 0 rgb(0 0 0 / 15%);
  transition: box-shadow 0.2s cubic-bezier(0.45, 0, 0.55, 1);
}
</style>
