<template>
  <div class="hello">

    <Header/>

<div class="main-wrapper ">


<Gordon />

	<Footer />
    </div>
  </div>
</template>

<script>

import Header from '../components/Header';
import Footer from '../components/Footer';
import Gordon from '../components/GordonBig';


export default {
  name: 'TeamGordon',
  components:{
    Header,
    Footer,
    Gordon
  },
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.shadow{
  box-shadow: 0 0 58px 0 rgb(0 0 0 / 15%);
    transition: box-shadow .2s cubic-bezier(.45,0,.55,1);
}
</style>
