<template>
  <div class="hello">
    <Header />

    <div class="main-wrapper ">
      <section class="page-title bg-1">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <h1 class="text-capitalize mb-4 text-lg">Medical & Health</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Section About Start -->
      <section class="section about-2 position-relative">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="about-item-img">
                <img
                  src="assets/images/medical.jpeg"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="about-item pr-3 mb-5 mb-lg-0">
                <span class="h2 text-color">Medical Health</span>
                <h5 class="mt-3 mb-2 position-relative">
                  Envision Equity Group is interested in acquiring hospitals,
                  medical & dental practices, nursing homes, medical, healthcare
                  equipment manufacturing companies, pharmaceuticals, diagnostic
                  laboratories, and pharmacies.
                </h5>
                <p class="mb-4">
                  According to Forbes and Fortune, Medical companies represent
                  more than $2 billion industry with a 3.6% growth rate. The
                  medical courier industry, worth about $4 billion, deals
                  specifically with the transport and delivery of vital fluids
                  and organs to laboratories and facilities nationwide. The
                  ambulance services sector brings in roughly $15 billion and is
                  growing at a 4% clip annually.
                </p>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="about-item pr-3 mb-5 mb-lg-0">
                <p class="mb-5">
                  The medical group practice sector is a $296 billion industry
                  and includes family practice, pediatrics, obstetrics, internal
                  medicine, and other medical specialties. The healthcare
                  industry is one of the world’s largest and fastest-growing
                  industries. Consuming over 10 percent of gross domestic
                  product (GDP) of most developed nations, health care can form
                  an enormous part of a country’s economy. Current evaluations
                  put US healthcare spending at around 15% of GDP, which is the
                  most astounding on the planet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "Team",
  components: {
    Header,
    Footer,
  },
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.shadow {
  box-shadow: 0 0 58px 0 rgb(0 0 0 / 15%);
  transition: box-shadow 0.2s cubic-bezier(0.45, 0, 0.55, 1);
}
</style>
