<template>
  <div class="hello">
    <Header />

    <div class="main-wrapper ">
      <section class="page-title bg-1">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <h1 class="text-capitalize mb-4 text-lg">Vision & Mission</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section testimonial">
        <div class="container">
          <div class="shadow row">
            <div class="col-md-9 p-3">
              <h2>Vision Statement</h2>
              <h4 class="text-color h4">
                An affordable hospital bed for anyone who needs it <br />
                <small>
                  to return them to full health & wellness throughout North
                  America by the end of the decade.
                </small>
              </h4>
            </div>
          </div>
        </div>

        <div class="container mt-5">
          <div class="shadow row">
            <div class="col-md-10 p-3">
              <h2>Mission Statement:</h2>
              <h4 class="text-color h4">
                Provide affordable patient-centered healthcare <br />
                <small>
                  - With excellence in quality, service, and access

                  <br />
                  - With above market rate returns to our investors
                  <br />
                  - With the highest level of visceral satisfaction for our
                  Medical Practitioners
                  <br />
                  - With maximum contribution to the needs of our community
                </small>
              </h4>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "MissionVision",
  components: {
    Header,
    Footer,
  },
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.shadow {
  box-shadow: 0 0 58px 0 rgb(0 0 0 / 15%);
  transition: box-shadow 0.2s cubic-bezier(0.45, 0, 0.55, 1);
}
</style>
