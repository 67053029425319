<template>
  <div class="hello">
    <Header />

    <div class="main-wrapper ">
      <section class="page-title bg-1">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <h1 class="text-capitalize mb-4 text-lg">Team</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <TeamMember />

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import TeamMember from "../components/TeamMember";

export default {
  name: "Team",
  components: {
    Header,
    Footer,
    TeamMember,
  },
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.shadow {
  box-shadow: 0 0 58px 0 rgb(0 0 0 / 15%);
  transition: box-shadow 0.2s cubic-bezier(0.45, 0, 0.55, 1);
}
</style>
