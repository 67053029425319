<template>
  <div class="hello">
    <section class="section testimonial">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 ">
            <div class="section-title">
              <span class="d-none h6 text-color"
                >Each Executive Board member is responsible and accountable for
                a defined area of the business.</span
              >
              <h2 class="mt-0 content-title">Our Team</h2>
            </div>
          </div>
        </div>
        <div class="shadow mb-3 row">
          <div class="col-md-3 p-3">
            <img class="img-fluid" src="assets/images/team/team-1.jpeg" />
          </div>
          <div class="col-md-9 p-3">
            <h2>JAY R. GAJAVELLI</h2>
            <h4 class="text-color h4">Founder and Director</h4>
            <h6>jay.rao@envisionequitygroup.com</h6>
            <p>
              Jay Gajavelli is the founder of <b>Envision Health Group</b>.
              <br />
              <br />
              I am an Entrepreneur & Investor. I am passionate about life and building win/win relationships in the way I acquire cash flowing apartment buildings and businesses. The best part for me is adding value to these enterprises, enhancing their operations, and getting excellent returns on investment. 
              <br />
              <br />
              I built substantial wealth for myself and others as the first part of my life’s mission. Now, I am addressing the greater part of my mission: Utilize my wealth and my relationships to provide affordable patient-centered healthcare with excellence in compassion, service, and outcome. Towards the fulfillment of this purpose, I am now acquiring large Hospitals, Medical Centers, and Manufacturers of Medical Products and Devices. 
              <br />
              <br />
              I am really passionate about my mission and the soul-renewing impact it will have on millions of people worldwide.
              <br />
              <br />
              I came from India 20 years ago as an Engineering & Computer Consultant and worked in Corporate America for more than 16 years. My clients were the giants of their field: Ford, Caterpillar, GM, AT&T, Department of Defense, Tate & Lyle, Koch, Compuware, Accenture, Infosys, and Deloitte. 
              <br />
              <br />
              I became a US citizen in 2007, live in the moment, and enjoy simple things in life.
              <br />
              <br />
              The causes I care about: Children, Environment, and Disaster &
              Humanitarian Relief.
              <br />
              <br />
              The Organizations I support: American Red Cross, United Way
              Worldwide, Make-A-Wish America, World Vision, Chair The Love,
              Anthony Robbins Foundation, and Joel Osteen/Lakewood Church.
            </p>
          </div>
        </div>
        <br />
        <div class="shadow row mt-4">
          <div class="col-md-3 p-3">
            <img class="img-fluid" src="assets/images/team/gordan.jpeg" />
          </div>
        <div class="col-md-9 p-3">
            <h2>GORDON BIZAR</h2>
            <h4 class="text-color h4">Principal (Strategies & Structures)</h4>
            <h6>gordon@envisionequitygroup.com</h6>
            <p>
              <b>
                Gordon is the former Chairman of the California Task Force on
                Taxation and Regulation of Small Business during the first
                “Jerry” Brown administration and is sought after as a consultant
                by businesses large and small and government agencies such as
                NASA for their technology transfer program.
              </b>

              <br />
              <br />
              Gordon is the CEO of National Diversified Funding Corporation and
              an internationally acclaimed visionary, innovator, and
              entrepreneur with a 55-year success track record. During this
              extensive period, through the best and worst of times, he bought
              and built companies in fields as diverse as manufacturing,
              financial services, and business education. Battle hardened and
              tempered, Gordon is uniquely qualified to guide businesses through
              both tough and vibrant economic conditions.
              <br />
              <br />

              His unique expertise and success track record has led to his
              appearances on NBC’s Today Show and PBS’s Late-Night America along
              with segments on more than 120 other radio and television news and
              talk shows. He has been featured in articles in more than 25 of
              America’s leading newspapers including the Los Angeles Times, New
              York Times, Wall Street Journal, Chicago Sun Times, Houston
              Chronical, Dallas Morning News, Boston Globe and Miami Herald.
              <br />
              <br />
              Gordon is one of the top platform speakers for business growth and
              acquisition worldwide. His name is associated with some of the top
              names in personal and business development. Here are just some of
              his recent associations over the past decade. • Tony Robbins
              invited Gordon to be a featured speaker at Tony’s Platinum
              Partners event in South Beach, Florida.
              <br />
              • Jay Abraham invited Gordon to be a featured speaker at Jay’s
              Business Summit event in Anaheim, California. In addition, Gordon
              and Jay joint ventured training programs focused on internal
              growth through sales & marketing and external growth through
              acquisition of competing and complimentary companies. Another
              Gordon-Jay JV, The Wealth Factory, also trained and consulted
              entrepreneurs in business growth.
              <br />
              • Chris Howard invited Gordon to be a featured speaker at two of
              Chris’s Billionaire Boot Camps, one held in Rio de Janeiro and the
              other in Belize.
              <br />
              • Chet Holmes teamed up with Gordon to market Gordon’s Global
              Aggregation Corporation.
              <br />
              • In Gordon’s Relight America Campaign, Gordon shared his
              web-based platform to feature other top business experts to his
              audience of more than 6,000 business owners and aspiring
              entrepreneurs. Some familiar names include John Assaraf, Dr. Lisa
              Lang, Marcia Weider, Gary Ryan Blair, and Paul Lemberg.
              <br />
              • Doria (DC) Cordova, PhD (Hon.), CEO invited Gordon to be a
              featured speaker at her Global Excellerated Business School event
              in Phuket, Thailand.
              <br />
              As trainer, coach and mentor, Gordon simplifies complex business
              purchasing and financing. He makes understandable the use of
              financial leverage to enable people to start, buy or expand any
              business with little or none of their own cash, despite a
              difficult credit marketplace. Some refer to his expertise as “how
              to make next to nothing accomplish the next to impossible.”
              <br />
            </p>
            <h4>CAREER HIGHLIGHTS</h4>

            <p>
              • Raised more than $212 million in equity capital in addition to
              negotiating credit facilities as small as $100,000 from TOPA
              Thrift and Loan to as large as $500 million from Lehman Brothers.
              <br />
              • Pioneer in the development and use of value-added up-sell
              product and service profit centers such as 900 number telephone
              advisory lines; specialty how-to manuals, books, audios, and
              videos; business acquisition and management consulting;
              newsletters; business and loan brokerage; specialized computer
              software; and networking services.
              <br />
              • Built Bizar Financing, a business support organization and
              finance course on “how to start, buy and expand a business using
              little or none of your own cash,” into an international phenomenon
              with more than 350,000 students spread across America and
              seventeen other countries.
              <br />
              • Bought controlling interest in National Diversified Funding
              Corporation; a Los Angeles based private equity investment
              company.
              <br />
              • Pioneered the Integrated Financial Services Industry as
              co-founder of Financial Congeneric Corporation (FCC), a holding
              company Gordon brought public in 1971. FCC acquired financial
              service firms ranging in activities from mutual fund sales,
              insurance sales, real estate sales, asset leasing and finance to
              stock brokerage and business management. Gordon then,
              cross-marketed each company’s client base with the products and
              services of the other acquired companies to radically increase
              sales and profit.
              <br />
              United States Senator Robert C. Byrd, Chairman of the powerful
              Senate Appropriations Committee, personally invited Gordon to be
              an active financing innovator in his effort to bring
              technology-based businesses to his home state of West Virginia.
              Gordon was the only non-West Virginian to be invited by Senator
              Byrd to speak over multiple years at his annual Software Valley
              conferences to help foster the growth of the State’s technology
              sector as an alternative to its economic dependency on coal
              production.
              <br />
            </p>
            <h4>CURRENT FOCUS</h4>
            <p>
              Founder of ScaleForce, LLC, a technology facilitated business
              collaboration platform that automates cross-marketing between
              partnering B2B product and service companies. The platform also
              enables a business owner or management to monitor the growth and
              productivity of their company on any device from anywhere in the
              world.
            </p>
            <h4>EDUCATION</h4>
            <p>
              Boston University (BU), Economics and Finance Rensselaer
              Polytechnic Institute (RPI), Physics
            </p>
            <router-link
                  class="btn btn-main btn-small"
                  to="/team/gordon"
                  >Read More</router-link
                >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "TeamMember",
  props: {},
};
</script>

<style scoped>
.section-title {
  margin-bottom: 20px;
}
</style>
