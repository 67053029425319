<template>
  <div class="hello">
    <section class="section testimonial">
      <div class="container">
        <div class="shadow row mt-0">
          <div class="col-md-3 p-3">
            <img class="img-fluid" src="assets/images/team/gordan.jpeg" />
          </div>
          <div class="col-md-9 p-3">
            <h2>GORDON BIZAR</h2>
            <h4 class="text-color h4">Principal (Strategies & Structures)</h4>
            <h6>gordon@envisionequitygroup.com</h6>

            <b>
              Gordon Bizar, more than half a century of success in the
              fields of Business, Finance, Adult Education, Media, and Public
              Service.
            </b>

            <h4>
              Today
            </h4>

            Gordon is CEO and Board Chairman of
            <a target="_blank" href="http://nationaldiversified.com/"
              >National Diversified Funding Corporation</a
            >
            , a Los Angeles-based global private equity company. He is sought
            after by entrepreneurs, business executives and government leaders
            worldwide for his ability to foresee, strategize, structure and
            organize innovative solutions to seemingly impossible business and
            societal challenges. When stuck with the most vexing challenges,
            leaders and power brokers call Gordon. <br />The scope of the
            missions currently undertaken by Gordon are grand in scale and
            disruptive in their implications. <br />
            • Under the brand names of Crisis Business Success TeamTM and
            Reignite AmericaTM – Help small businesses survive and even thrive
            through nation’s COVID-19 pandemic lockdown. Then, emerge even
            stronger as the nation reopens its economy.
            <br />
            • Under the brand names of Bizar FinancingTM and Partners in
            Business AcquisitionTM – Facilitate the Transfer of $12 Trillion of
            Business Wealth from Retiring Baby Boomers owners to Gen X & Y
            Entrepreneurs.
            <br />
            • Under the brand name Accountant Partnering SystemTM – Reposition
            CPA firms from providers of Tax & Compliance Advisory into a Total
            Business Solution Centers.
            <br />
            • Under the brand name Billionaire LifestylesTM – Reposition and
            expand Family Offices from wealth management to total lifestyle
            support for high income, high net worth individuals and their
            families.
            <br />
            • Under the brand name ScaleForceTM – Establish Strategic
            Aggregation and Ecosystem Cross Marketing as the dominant business
            model for creating wealth in the 21st Century. Build a
            technology-facilitated platform fully automating cross marketing and
            other areas of collaboration among aligned businesses.
            <br />
            • Train 3,000 Certified Business Strategists® and 2000 Certified
            Concierge ProfessionalsTM to replicate Gordon’s challenge solving
            capabilities and carry them forward to future generations.
            <br />
            • Under the brand name Relight AmericaTM – Establish Socially
            Conscious Capitalism, or as Gordon calls it “ScCapitalism”, as the
            worldwide replacement for raw or even regulated Capitalism.

            <h4>A Sixty-Year Journey</h4>

            What is it about Gordon’s lifelong journey that has positioned him
            to undertake these missions? <br />
            Gordon grew up in a family of retail business owners going back
            several generations on both his mother’s and father’s sides of the
            family. Dinner table conversation throughout his youth prepared him
            for the business world. When he was 15 years old, he began sweeping
            floors and other maintenance functions in one of his father’s floor
            covering stores. By age 16 he was selling carpeting and tile and by
            age 17 became the night manager of that location.
            <br />
            <br />
            By the time he left home to attend college in 1962, he had already
            engaged in a number of entrepreneurial efforts that yielded him his
            own muscle car, high powered speedboat and first year’s college
            tuition including room and board at Boston University. Gordon
            developed two new companies while attending college that paid all of
            his remaining college and lifestyle expenses with a surplus. One was
            a modelling agency providing attractive college co-eds for fashion
            shows at women’s apparel boutiques. The other was a taxi cab rental
            that Gordon drove on a night shift taking him from the mansions of
            Chestnut Hill to the seamy underbelly of Boston’s “Combat Zone” to
            the violent and crime ridden back alleys of Roxbury. In addition to
            an abundance of spending cash, one business provided Gordon with an
            incredible social life while the other provided an unvarnished
            insight into the harsh realities of urban life.
            <br />
            <br />
            In 1967, after receiving his formal training in Economics and
            Finance at Boston University, Gordon moved to California and became
            a multimillion dollar producing insurance agent with Connecticut
            Mutual Life Insurance Company. Less than three years later, he
            parlayed his training, experience and contacts into becoming manager
            for the West Los Angeles office of Home Life Insurance Company of
            New York. Less than two years later, he started and managed his own
            top producing independent insurance agency. The secret to Gordon’s
            rapid rise in the life insurance industry was rooted in the “Go-Go”
            days of Wall Street. He partnered with investment banking companies
            to insure the lives of the majority shareholders and management
            teams of the companies they were taking public during the hot new
            issues market of the late 60’s. Concurrently, Gordon foresaw the
            coming of an integrated financial services industry. He pioneered
            the Financial Department Store Concept as cofounder and Vice
            President of Financial Congeneric Corporation, a holding company
            that he took public in July 1971. He became CEO in 1971. From 1969
            through 1974, he engineered FCC’s acquisition of his own life
            insurance agency and other financial service firms ranging in
            activities from mutual fund sales, general insurance sales, asset
            leasing and finance, stock brokerage, business management and wealth
            management. Gordon also developed the client cross marketing
            strategies that dramatically increased sales of each acquired
            company. This was the birth of Strategic Aggregation which was yet
            to be named as the business model he would evolve and fine tune over
            the next 45+ years.
            <br />
            <br />

            In 1973, Gordon bought controlling interest in National Diversified
            Funding Corporation; a Los Angeles based private equity company. He
            has continuously served as CEO and Chairman of the Board since the
            purchase.

            <br />
            <br />

            In 1974, Gordon expanded his horizon to encompass companies in
            various fields of manufacturing. This began with his leveraged
            buyout of Grand Prix Cycle Products followed by Elevator Products of
            California and Grant Machine Works. To this base, he then added
            National Recreation Products and the fork tube division of Wheel
            Specialties, Inc. Unlike the classic leveraged buyouts of the 80’s
            with purchases of large, inefficient public companies followed by
            liquidation for asset value, Gordon bought smaller owner operated
            companies and combined them for better purchasing power,
            distribution product mix and other economies of scale. It was during
            this period that Gordon mastered the art of leveraged buying that
            was to become the basis of his internationally taught business
            finance course, Bizar Financing.
            <br />
            <br />

            From 1980 through the present, Gordon built his business finance
            course into an international phenomenon expanding distribution into
            forty of America’s largest cities and seventeen foreign countries.
            He pioneered the development of the ½ hour-long television program
            commercial known as an “infomercial”. His show “Getting Rich Your
            Way”, a TV infomercial on how to start or buy your own successful
            business without capital, became one of the most successful
            infomercials ever produced to attract people to educational events.
            It yielded more than 300,000 paid course participants. Years of
            seven figure profits were common, aided by the development of
            backend product and service profit centers such as a 900 number
            telephone Advisor Line; specialty “how to” manuals, books, and audio
            and video cassette tapes (which later became CD’s and then content
            delivered via the Internet); business acquisition and management
            consulting; newsletter; business and loan brokerage; specialized
            computer software and networking services. Revenues were further
            enhanced by licensing to other presenters the rights to give the
            courses and sell the backend products and services in cities
            domestic and foreign that Gordon did not have time to serve in
            person or where he did not speak the language. Since its
            introduction in 1980, Bizar Financing has resulted in deal flow to
            National Diversified Funding Corporation yielding more than 3,500
            acquisition and merger transaction spanning 5 continents.

            <br />
            <br />

            Gordon is currently the innovator and architect of Strategic
            Aggregation. He leads a team of 85 multi-discipline entrepreneurs
            and business professionals disbursed throughout North and South
            America, Europe, Asia and Australia conducting simultaneous
            worldwide aggregations of ten strategically defined industries. The
            extraordinary results are being achieved by the ability of Strategic
            Aggregation to solve the needs of small and mid-sized companies to:
            1) gain access to needed capital, 2) develop new and better methods
            of marketing their primary products and services, 3) develop and
            exploit multiple new revenue sources from the clientele they already
            serve, 4) improve their supply chain with enhanced global sourcing,
            5) expand their purchasing power to drive down costs, and 6) utilize
            office and business automation to increase efficiency. These
            capabilities enable companies struggling for survival to thrive in
            today’s fast evolving information-based global economy.

            <br />
            <br />

            Gordon’s response to the 2007-2008 worldwide Financial Meltdown was
            classic Bizar… get information into the hands of business owners
            they need to overcome the challenge. Within weeks of the market
            collapse, Gordon invented Relight America™. This project rapidly
            brought together several dozen of America’s leading business experts
            to help companies survive and then thrive through those tough
            economic times. Relight America™ used the advanced communication
            technologies of the internet to train and support companies through
            their challenges to increase sales, cut costs, and get financing for
            their survival or growth.

            <br />
            <br />

            A veteran of thousands of seminar presentations and personal
            appearances, Gordon is one of the top platform speakers for business
            acquisition and growth worldwide. His name is associated with some
            of the top names in personal and business development. Here are just
            some of his associations over the past decade.

            <br />

            • Tony Robbins personally invited Gordon to be a featured speaker at
            Tony’s Platinum Partners event in South Beach, Florida.
            <br />
            • Jay Abraham personally invited Gordon to be a featured speaker at
            Jay’s Business Summit event in Anaheim, California. In addition
            Gordon and Jay joint ventured training programs focused on internal
            growth through sales & marketing and external growth through
            acquisition of competing and complimentary companies. Another
            Gordon-Jay JV, The Wealth Factory, also trained and consulted
            entrepreneurs in business growth.
            <br />
            • Chris Howard personally invited Gordon to be a featured speaker at
            two of Chris’s Billionaire Boot Camps, one held in Rio de Janeiro
            and the other in Belize.
            <br />
            • Chet Holmes teamed up with Gordon to market Gordon’s Global
            Aggregation Corporation.
            <br />
            • In Gordon’s Relight America™ Campaign, Gordon shared his web based
            platform to feature other top business experts to his audience of
            more than 6,000 business owners and aspiring entrepreneurs. Some
            familiar names include John Assaraf, Dr. Lisa Lang, Marcia Weider,
            Gary Ryan Blair and Paul Lemberg.
            <br />
            • Doria (DC) Cordova, PhD (Hon.), CEO personally invited Gordon to
            be a featured speaker at her Global Excellerated Business School®
            event in Phuket, Thailand.
            <br />

            <h4>
              Gordon’s Efforts for the Benefit of Small Business Owners and
              Entrepreneurs
            </h4>

            Along the way Gordon has taken time to engage in other projects that
            serve to increase the ability of small business owners to succeed.
            In 1978, he organized the Beverly Hills business community to fight
            a 2000% increase in their Business License Tax prompted by that
            city’s property tax revenue losses from Prop.13. Gordon, with help
            from Howard Jarvis, raised the several hundred thousand dollars
            needed for that effort. From 1980 to 1983, Gordon raised more than
            $6,000,000 for the National Institute of Business Achievement, a non
            profit corporation that he founded in 1980 to help train
            entrepreneurs.
            <br />
            <br />
            Gordon has also been Chairman of the California Task Force on
            Taxation and Regulation of Small Business under the Brown
            administration. He has been a visiting lecturer to Pepperdine
            University’s MBA Program, and a guest speaker at numerous
            conventions conferences and symposiums worldwide. He is continually
            sought after as a consultant by businesses large and small and
            government agencies such as NASA for their technology transfer
            program. He has appeared on more than 120 radio and television news
            and talk shows such as the Today Show on NBC. Gordon and his
            activities have also been the subject of feature articles in more
            than 25 of the nation’s leading newspapers including the Los Angeles
            Times, New York Times, and Wall Street Journal.
            <br />
            <br />
            In 1991, Gordon founded BetterAmerica, a 501(c)(4) non profit Civic
            League with the mission to increase citizen participation in
            government, replace professionals with citizen politicians, replace
            special interest funding of elections with funding from a
            candidate’s local constituency and develop local and national
            leadership that will solve our nation’s problems by enabling
            cooperation and partnership as an alternative to polarization and
            debate.

            <h4>Other non profit organizations founded by Gordon Bizar are:</h4>

            National Independent Business Alliance, 1978, a 501(c)(6) Business
            League with a mission to look out for the interests of small
            business. This organization successfully sponsored legislation that
            reduced the tax and regulatory burdens on small business. It also
            succeeded in litigation that prevented local California governments
            from recapturing their Prop.13 revenue losses through new taxes on
            local businesses.
            <br />

            International Business Network, 1980, a 501(c)(6) Business League to
            encourage the linking of small businesses worldwide to solve mutual
            problems and distribute educational products and services geared
            toward promoting entrepreneurship.

            <br />
            National Institute for Business Achievement, 1980, a 501(c)(3)
            Educational Foundation to research and develop educational materials
            to promote entrepreneurship.
            <br />

            Gordon’s hallmarks are vision, innovation and leadership. These,
            combined with his talent for creating effective organizations, make
            him an important member of any leadership team.

            <h4>
              Educational and Training Highlights – As Student
            </h4>

            Connecticut Mutual Life Insurance Company course for obtaining Life
            and Disability Agents License 1962.
            <br />
            <br />
            Connecticut Mutual Life Insurance Company course for Basic Sales
            Training for Life Insurance 1962.
            <br />
            <br />
            Training Course for General Insurance Brokers License 1964.
            <br />
            <br />
            Boston University 4 years, majors in Economics and Finance, AB
            Degree 1965.
            <br />
            <br />
            Connecticut Mutual Life Insurance Company course for Advanced Sales
            Training for Life Insurance 1967.
            <br />
            <br />
            Connecticut Mutual Life Insurance Company courses for Basic and
            Advanced Estate Planning 1967. These courses convey detailed and in
            depth information on Income and Estate Tax consequences and methods
            of legal tax avoidance as well as uses of various life insurance and
            annuity products to produce these ends. They also included use of
            various trusts and charitable gift programs. Also included were
            sources and methods to research tax laws and current case rulings.
            <br />
            <br />
            International Business Machine (IBM) courses for computer
            programming using Basic and use of remote timesharing terminals.
            These courses were taken in 1968.
            <br />
            <br />
            Home Life Insurance Company of New York course on Financial Planning
            1969. Course included how to analyze family needs for; retirement
            income, emergency reserves, cash and income at the death of the
            bread winner or spouse and then determine which mix of investment
            products (securities, cash value or term life insurance, bank
            savings, etc.) were appropriate given the goals and the income of
            the client.
            <br />
            <br />
            Home Life Insurance Company of New York course on Agency Management
            1969. Course included management skills and knowledge necessary to
            run a branch office for the company, including how to hire, train
            and sustain sales and office personnel.
            <br />
            <br />
            Home Life Insurance Company of New York courses on Pension, Profit
            Sharing, Keogh, and ESOT including their tax ramifications and how
            to use them to reduce client tax obligations. These courses were
            taken during 1969 and 1970.
            <br />
            <br />
            Conference on Taking Your Company Public. Topics included
            accounting, choosing an underwriter, the various types of public
            offerings (Reg. A, S1 and S2), legal liabilities to investors,
            necessary disclosures, risks vs. benefits, compliance with; various
            federal investment acts, state “blue sky” laws, the SEC, Secretary
            of State, Department of Corporations, State Attorney Generals and
            other federal and state regulatory agencies. This training was
            attended during 1969.
            <br />
            <br />
            Course for NASD securities financial principal’s license, 1970.
            <br />
            <br />
            From 1967 to 1973, attended several conferences each year sponsored
            by the Insurance Industry and the Securities Industry to update
            knowledge and skills and keep abreast of changes in marketing
            methods and tools, new products and changes in tax and securities
            laws and regulations.
            <br />
            <br />
            From 1974 through 1978, attended 1 to 2 conferences each year
            sponsored by various manufacturing and other business associations.
            These conferences dealt with topics ranging from manufacturing
            methods and techniques, cost control, accounting, sales and
            marketing, quality control, personnel -hiring and firing, workman’s
            compensation, compliance with various government regulatory agencies
            (such as OSHA, CALOSHA, EPA, etc.), product liability insurance,
            financing methods and sources and other topics necessary to operate
            a small to mid size manufacturing firm.
            <br />
            <br />
            Time Management seminar, sponsored by The Time Bank. Topics included
            methods for organizing information and one’s time to be more
            effective at accomplishing tasks. Also supplied were advance
            planning tools such as To Do sheets, Daily, Weekly, Monthly, Yearly
            and Five Year Planners. Other tool incorporated ways of organizing
            information regarding clients, tasks, and events. This seminar was
            attended in 1978.
            <br />
            <br />

            Personal Effectiveness Training, sponsored by PSI World including
            their Four Day Basic Training and Five Day Advanced Training. The
            focus of these training’s is understanding yourself, your place in
            the grand scheme of things, learning who you are and what your
            capable of, learning how to clear internal and external obstacles,
            developing and achieving goals, creating mental focus and techniques
            for improving memory and creating inner calm during periods of
            stress and how to develop and accomplish a life vision. Both of
            these training were attended during 1979.

            <br />
            <br />2-day Internet Conference sponsored by Guthy-Renker. Topics
            included an overview of the Internet, an in-depth view of the World
            Wide Web, how it works, how to establish a website, orientation to
            available design software, marketing through a website, sampling of
            the various types of sites, definitions of technical terms, usage
            and growth statistics and sources of Internet service providers and
            other Internet services. This conference was attended in 1995.

            <br />
            <br />
            The Inaugural Forum on Music and Motion Picture Revenues & Royalties
            Securitization presented by Frank J. Fabozzi / Information
            Management Network. Topics included an overview of asset-backed
            securities as they pertain to the entertainment industry, how to
            structure the deal, securitization mechanics, function of the rating
            agency, use of credit enhancements, tax & estate planning issues,
            accounting treatments and other securitization issues.

            <h4>
              Educational and Training Highlights – As Presenter
            </h4>

            Guest lecturer for course on Business Structure and Strategy
            Sponsored by Pepperdine University MBA Program under Professor
            Melvin E. Salveson, Ph.D. in Los Angeles, 1971. Lecture topics were;
            Techniques of Corporate Acquisition and Tax Consequences of Business
            Acquisitions. Professor Salveson was the inventor of the modern day
            bank credit card which became MasterCard and VISA.
            <br />
            <br />
            Marketing for the ‘80’s, full day conference sponsored by the
            International Business Network in Los Angeles in 1979. Along with 26
            other speakers, each representing a different marketing discipline,
            covered every aspect of marketing products and services, with a
            focus on the demographic and buying pattern changes that would
            differentiate the ‘80s from the ‘70s.
            <br />
            <br />
            From 1980 through 1996, presented more than 700 seminars sponsored
            by the National Institute for Business Achievement on methods and
            strategies for Starting and Buying Businesses. These events were
            held throughout the following metropolitan areas (order by size rank
            of US market at that time):

            <img
              class="img-fluid w-75"
              src="/assets/images/gordon-table.png"
              alt=""
            />
            <br />
            From 1980 through 1996, presented more than 150 full weekend
            conferences sponsored by the International Business Network on how
            to use financial leverage in business. These events were held in the
            same cities listed above.
            <br />
            <br />From 1981 through 1984, presented more than 70 workshops
            sponsored by the National Institute for Business Achievement on such
            topics as small business finance, cash management, turnaround
            management, marketing and management. These events were held in Los
            Angeles. Instructors from the cities listed above, attended these
            events as part of their training to present them in their home city.
            It was from this hands-on audience interaction that Gordon created
            the Audience Receptivity Profile that enable him to map the social
            traits and preferences unique to people in a specific geographical
            locations. By mapping how audiences differed from city to city, and
            identifying other traits common to all audiences, Gordon was able to
            alter his content and style of delivery to achieve higher and higher
            levels of receptivity in his audience and in the audiences of
            instructors and other speakers who he trained.
            <br />
            <br />
            Conference on Development of High Tech Businesses, Software Valley
            III sponsored by US Senator Robert C. Byrd of West Virginia,
            attended as Featured Luncheon Speaker as well as a panelist in other
            sessions 1986. Re invited to participate on same basis for Software
            Valley VI 1987. In both cases, spoke on innovative ways to finance
            start up and small emerging high tech firms. Participated as
            panelist on other topics such as Seed and Venture Capital,
            Accounting and Investment Banking Tips for New High Tech Firms.
            <br />
            <br />
            In 1991 developed and presented full day conference to the members
            of the Black Business Association of Los Angeles with emphasis on
            minority entry into businesses ownership and financing strategies
            tailored to the unique needs of minority business owners.
            <br />
            <br />
            In 1998 developed and presented a series of 4-hour training programs
            for accountants, business managers and attorneys demonstrating
            advanced cash management and estate planning techniques using
            securitization of cash flows from intellectual property rights to
            create large amounts of tax free cash for owners.
            <br />
            <br />
            In 2007 developed and presented “Aggregation to Global Market
            Domination”, two-day event in Los Angeles attended by 125 handpicked
            successful entrepreneurs and business professionals from North and
            South America, Europe, Asia, and Australia. Eighty-five of the
            attendees now work with Gordon in the aggregation of 10 strategic
            industries.
            <br />
            <br />
            In 2006 Gordon began exploiting both internet and phone technology
            to conduct Webinars (seminar training courses and educational events
            utilizing internet and phone systems). Utilizing these technologies,
            Gordon currently conducts 2 – 1½ hour Webinars per week to audiences
            ranging in size from 50 to 800 participants worldwide. These events
            have the side benefit of developing both the deal flow and qualified
            personnel required to aggressively feed and propel the growth of
            Gordon’s many business interest.
            <br />
            <br />
            Bizar’s first post-2008 Financial Meltdown project was Relight
            America™. To help revitalize the economy, Gordon assembled two dozen
            of the greatest business minds of the 21st Century to deliver a ten
            week series of webinars. These training events help business owners
            take immediate control of their financial future and kick start our
            lagging economy. Each week topics focus on knowledge and resources
            that can help businesses increase sales, decrease costs and get the
            financing they need to grow.
            <br />
            One of Gordon’ recent speaking engagements in Sydney Australia is
            available on the National Diversified website. His topics were “The
            Eleven Most Important Things that I Learned during a Half Century in
            Business” and “Quantum Growth through Business Acquisition –
            Leverage the Assets & Cash Flow of a Business to Fully Pay for its
            Acquisition.”

            <h4>Professional Licensees Held</h4>

            <b>Insurance </b>
            Life and Disability Insurance Agent’s License, New York State.
            Effective 1962 until moved to California 1967. Not renewed because
            business and residence moved to California.
            <br />
            General Insurance Broker’s License, New York State. Effective 1964
            to 1967. Not renewed because business and residence moved to
            California.
            <br />
            Life and Disability Insurance Agent’s License, California. Effective
            1967 to 1990. Not renewed because no longer active in roles
            requiring the license.
            <b>Securities</b>
            NASD Securities Broker, Licensed Financial Principal, California.
            Effective 1970 through 1976. Not renewed because no longer active in
            roles requiring the license.

            <h4>
              Fame and Fortune without Infamy
            </h4>

            It is rare that someone with the depth and breadth of Gordon’s
            background reaches such a high level of achievement with the virtual
            absence of negative business life episodes. Yet Gordon has done it.
            He attributes this to his passion to help entrepreneurs succeed, his
            commitment to provide superior value with every transaction, a value
            system that demands integrity from himself and his organization and
            which embraces empathy for others.
            <br />To learn more about Gordon, just enter “Gordon Bizar” in a
            Google, Yahoo or YouTube search box or in the search box of any
            other internet search engine.

            <h4>
              Summary by the Decade
            </h4>
            Gordon has played a pioneering and leadership role in virtually all
            of the major business movements and trends of the last 6 decades,
            each positioning him for leadership in the next.
            <br />
            <br />
            <b
              >2020’s – Rescuing Small Businesses from the COVID-19 Pandemic
              Lockdown</b
            ><br />
            Assisting small business owners nationwide file for and obtain EIDL
            and PPP survival financing. Organizing entrepreneurs into teams and
            mustering financing resources capable of fully supporting the
            comeback of cash starved small businesses in the aftermath of the
            COVID-19 Pandemic.
            <br />
            <br />
            <b>ScaleForce™ </b>
            deploys Gordon’s innovate technology and Strategic Aggregation
            business model to disrupt the B2B marketplace. This
            technology-facilitated business platform automates collaboration
            among multiple business entities that can function as if they were
            one fully integrated entity seamlessly cross-marketing their
            clients, sharing revenue, and integrating their service offerings
            for greater revenue and higher customer satisfaction.
            <br /><br />
            <b
              >2010’s – Disruptive Business Models - Strategic Aggregation and
              Building Ecosystems</b
            >

            Pioneering Strategic Aggregation as a disruptive business model into
            three major fields. Strategic Aggregation provides automated
            cross-marketing of companies that serve an alike clientele,
            organized around a common marketing theme where the customers of
            each rapidly become the customers of most of the others.
            <br /><br />
            <b>Billionaire Lifestyles™</b>
            repositions family offices as a base to cross-market strategically
            aggregated providers of products and service dedicated to high
            income, high net worth individuals and families.

            <br /><br />

            <b>Account Partnering System™</b>repositions CPA firms as a base to
            cross-market strategically aggregated providers of growth and
            facilitation products and services to businesses and their owners.
            <br /><br />
            <b>FACCT™ </b> (an acronym for Facilities Acquisition and Capital
            Creation Transaction) purchases vacant big box stores and office
            towers, repurposes them exclusively for select strategically
            aggregated tenants who provide different products and services to a
            common type of clientele and cross-markets the customers of each one
            to another. These facilities convert an underperforming
            underprivileged neighborhood eyesore into a thriving prosperity
            center.

            <br /><br />
            <b
              >2000’s – Personal Development and Business Recovery after the
              2007 Financial Melt Down </b
            ><br />
            Emerged as one of the top platform speakers for business acquisition
            and growth internationally. During this period Gordon teamed up with
            some of the top names in personal and business development including
            Tony Robbins, Jay Abraham, Chris Howard, Chet Holmes and many other
            thought leaders.
            <br />
            In Gordon's Relight America Campaign Gordon shared his web-based
            platform to feature other top business experts to his audience of
            more than 7,000 business owners and aspiring entrepreneurs. Some
            familiar names include John Assaraf, Dr. Lisa Lang, Marcia Weider,
            Gary Ryan Blair and Paul Lemberg.
            <br /><br />
            <b
              >1990’s – Participation TV, the Internet, and Securitization of
              Music Royalties</b
            >
            Pioneered Participation TV with the production of the TV show
            Solutions USA. The show focused on common challenges faced by
            viewers and connected them to solutions using phone touch tones and
            Internet connected PC’s as the return communication paths. Viewers
            could change the trajectory of the show and be part of creating
            their own solutions as well as solutions for others.
            <br />Pioneered the Internet by using TV to drive early Internet
            traffic and create online communities who shared common goals and a
            common purpose. Led the use of the Internet for online interactive
            training programs and the creation of digital content for worldwide
            distribution. <br />Pioneered the use of online webinars as a
            selling and delivery vehicle for information products worldwide.
            <br />Partnered with Kerry Gordy securing a half billion-dollar
            credit facility with Lehman Brothers for the securitization of music
            publishing rights for Kerry’s connections from Motown and Warner
            Bros.

            <br /><br />
            <b>1980’s – Infomercials and Personal Development</b>
            <br />
            Pioneered the development of the ½ hour-long television program
            commercial known as an “infomercial” producing the most successful
            TV infomercials ever to attract people to educational events.
            “Getting Rich Your Way” played almost unchanged from 1983 to 1990
            resulting in more than 350,000 student in the US and Canada for
            Gordon’s Business Finance Course – Bizar Financing. Getting Rich
            Your Way was one of the first infomercials to be accepted by major
            city independent TV stations and was the first to be accepted on
            ABC, NBC and CBS owned and operated stations. Segments of the
            original infomercial are still seen online today and continue to
            attract entrepreneurs to enroll in Bizar Financing Essentials and
            Bizar Financing Pro.
            <br />Bizar Financing is widely credited with popularizing LBO’s
            bringing business acquisition within the grasp of ordinary people.
            <br />Pioneered the seminar as the preeminent method for the sale
            and delivery of educational content. <br />Built the number one
            entrepreneur training company worldwide as part of the
            self-improvement movement of the 1980’s. Gordon took his experience
            as a leader in the Leverage Buyout Boom of the 1970’s and parlayed
            that into a course which first taught what he did and how he did it.
            The course then evolved to incorporate the successful adaptations
            created by and for his graduate base including the various methods
            they used to build their companies. Today, through 40+ years of
            continual interaction with and support of his graduate base, Bizar
            Financing is arguably the state-of-the-art for buying or building a
            business using financial leverage. But it goes further. It is now a
            leader in the development of new disruptive business models enabling
            graduates to rapidly dominate their marketspace.

            <br /><br />

            <b
              >1970’s – Leverage Buyout Boom and Recognition of Branding as a
              Key to Marketing</b
            >
            <br />
            Built an aftermarket motorcycle parts manufacturing giant with
            leveraged-buyouts of Grand Prix Cycle Products, National Recreation
            Products and the fork tube division of Wheel Specialties, Inc.
            combining them for better purchasing power, distribution, product
            mix and other economies of scale. Also purchased Elevator Products
            of California, Grant Machine Works, among others innovating the
            double escrow technique using the assets and the cash flow of the
            companies to create all of the cash required for their purchase.
            <br />Gordon’s branding and packaging innovations led the way for
            aftermarket motorcycle parts to be sold from a retail display rack
            instead pulled by a sales clerk from backroom storage shelves and
            sold over the counter virtually as a commodity. <br />Bought
            controlling interest in National Diversified Funding Corporation; a
            Los Angeles based private equity investment company. Gordon has
            since built NDFC into one of America's largest and most profitable
            private finance companies backing entrepreneurs on five continents.
            <br />As a leader in the California Tax Payer Revolt, in 1978 Gordon
            organized the Beverly Hills business community to fight a 2000%
            increase in their Business License Tax prompted by that city’s
            property tax revenue losses from Prop.13. Gordon, with help from
            Howard Jarvis, raised the several hundred thousand dollars needed
            for that effort.

            <br /><br />
            <b>Late 1960’s – Integration of Financial Services</b>
            <br />
            Partnered with investment banking companies to insure the lives of
            the majority shareholders and management teams of the companies they
            were taking public during the hot new issues market of the late
            1960’s “Go-Go” days on Wall Street.
            <br />
            Built one of the highest commission earning life insurance agencies
            in the US for Connecticut Mutual Life (which merged with Mass Mutual
            in 1996).
            <br />
            Inventor of the Financial Planner as a separate service to
            orchestrate client tax planning with the purchases of life
            insurance, mutual funds and real estate in accordance with clients’
            goals and available resources. This led the industry from a single
            salesperson pushing a single product to professional planners triple
            licensed in securities, life insurance and real estate to educate
            the client who then pulled the recommended products that would best
            accomplish their goals.
            <br />
            Built Financial Congeneric Corporation (FCC) as a provider of
            Certified Financial Planners as sales professionals of integrated
            financial services and products. FCC acquired securities firms,
            mutual fund sales organizations, investment real estate sales
            companies, life insurance sales agencies, finance companies and
            others to facilitate the delivery of these products and services to
            FCC clients. With this company, Gordon invented and pioneered the
            disruptive business model now known as Strategic Aggregation.
            <br />
            Took FCC public in 1971.
            <br />
            Sold controlling interest of FCC in 1973 to the owners of Jefferson
            National Life Insurance Company which was acquired by Nationwide in
            March of 2017.

            <br /><br />

            <b>Throughout these Many Decades</b>
            <br />
            Gordon’s unique expertise and success track record has led to his
            appearances on NBC's Today Show and PBS's Late Night America along
            with segments on more than 120 other radio and television news and
            talk shows. He has been featured in articles in more than 25 of the
            nation’s leading newspapers including the New York Times, Los
            Angeles Times, Wall Street Journal, Chicago Sun Times, Houston
            Chronical, Dallas Morning News, Boston Globe, Miami Herald, and
            Christian Science Monitor.
            <br />
            Gordon also served as Chairman of the California Task Force on
            Taxation and Regulation of Small Business during the first Brown
            administration and has been sought after as a consultant by
            businesses large and small and government agencies such as NASA for
            their technology transfer program.

            <br /><br />

            <br /><br />

            <br /><br />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Gordon",
  props: {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-title {
  margin-bottom: 20px;
}
</style>
