<template>
  <div class="hello">
    <Header />

    <!-- Header Close -->

    <div class="main-wrapper ">
      <!-- Slider Start -->
      <section class="slider">
        <div class="container">
          <div class="row">
            <div class="col-lg-9 col-md-10">
              <div class="block">

                <h1 class="animated fadeInUp mb-5">

                  Quality, Compassionate, <br />
                  Affordable Patient Care


                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Section Intro Start -->

      <section class="section intro">
        <div class="container">
          <div class="row ">
            <div class="col-lg-8">
              <div class="section-title">
                <h2 class="mt-3 content-title">
                  Exceptional People Give Exceptional Results.
                </h2>
              </div>
            </div>

            <div class="col-lg-12">
              <h2 class="mt-1">Envision Delivers</h2>
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="about-info-item mb-4 mb-lg-0">
                    <li>Doing what is right, fair, and ethical.</li>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="about-info-item mb-4 mb-lg-0">
                    <li>
                     Striving for professional excellence.
                    </li>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="about-info-item mb-4 mb-lg-0">
                    <li>
                      Considerate of staff and patient needs.
                    </li>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="about-info-item mb-4 mb-lg-0">
                    <li>Encouraging and rewarding teamwork.</li>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="about-info-item mb-4 mb-lg-0">
                    <li>
                      Adopting the highest possible standards.
                    </li>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="about-info-item mb-4 mb-lg-0">
                    <li>State-of-art solutions where needed.</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Section Intro END -->
      <!-- Section About Start -->

      <section style="padding-top:0px" class="section about position-relative">
        <div class="bg-about"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <img class="img-fluid" src="/assets/images/about.png" alt="" />
            </div>
            <div class="col-lg-6 offset-md-0">
              <div class="about-item ">
                <h2 class="mt-3 mb-2 position-relative content-title">
                  Envision Equity Group
                </h2>
                <h4 class="mb-3 position-relative">
                  Born in “Heart” of Texas
                </h4>
                <div class="about-content">
                  <p class="mb-5">
                    Envision Equity Group, comes straight from the heart of Texas. Socially conscious healthcare is the magnet drawing in the best doctors, nurses, and support staff, committed to making a difference in the wellness of our patients. Envision is a marriage of the finest in healthcare with superior facilities  management, producing win-win-win for our patients, staff, and socially conscious investors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Section About End -->

      <!--  Section Services Start -->
      <section class="section service border-top">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 text-center">
              <div class="section-title">
                <h2 class="mt-3 content-title ">
                  We Provide a full range of traditional and creative services.
                </h2>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="service-item mb-5">
                <i class="ti-desktop"></i>
                <h4 class="mb-3">PEOPLE - TO - PEOPLE CARE</h4>
                <p class="text-justify">
                 We are committed to bringing the human touch back to healthcare… for both our patients and staff. People are hungry today to be embraced by people who love them and care about them. We call it “food for the soul,” which plays a critical  role in patient recovery.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="service-item mb-5">
                <i class="ti-layers"></i>
                <h4 class="mb-3">INITIATIVE AND LEADERSHIP</h4>
                <p class="text-justify">
                  While most hospital and clinics are sterile and antiseptic in their look and feel, we have found a way to bring warmth and homyness back to healing process. Innovation and leadership make our facilities the optimum environment for both staff empowerment and patient recouperation.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="service-item mb-5">
                <i class="ti-bar-chart"></i>
                <h4 class="mb-3">COMMUNICATION</h4>
                <p class="text-justify">
                  Unlike, many providers of healthcare services where patients find doctors hard to reach and hard to understand when they finally get to talk to them, we train our staff including doctors in the art of patient communication substantially reducing patient stress levels.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--  Section Services End -->
      <!-- Section Cta Start -->
      <section class="section cta">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="cta-item  bg-white p-5 rounded">
                <span class="h2 mb-3 text-color">About Us</span>
                <p class="mt-3 mb-4">
                  <b>Envision Equity Group</b>, is a privately held investment
                  firm focused on acquiring medical facilities with outstanding patient services and higly competent staff. We
                  thrive on improving these facilities with long term captial investment and superior management.
                  <br />
                  <b>Our Vision</b> is socially conscious, quality, compassionate, affordable healthcare, profitably delivered.
                  <br />
                  <b>Our Goal</b> is to establish win/win long-term relationships with returning patients, staff, suppliers, and our investors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--  Section Cta End-->
      <!-- Section Testimonial Start -->
      <TeamMember />
      <!-- Section Testimonial End -->
      <section class="section latest-blog bg-2">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-8 mb-5">
              <div class="card bg-transparent border-0">
                <div class="card-body mt-2">
                  <div class="blog-item-meta">
                    <a href="#" class="text-white-50"
                      >Our Mission<span class="ml-2 mr-2"></span
                    ></a>
                  </div>

                  <h3 class="mt-3 mb-5 lh-36">
                    <p class="text-white "
                      >Povide compassionate, high quality healthcare, easily accessible and cost effective worldwide.
                      <br/>
                      Put quality healthcare within the reach of the general population worldwide.
                      </p
                    >
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import TeamMember from "../components/TeamMember";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    TeamMember,
  },
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.shadow {
  box-shadow: 0 0 58px 0 rgb(0 0 0 / 15%);
  transition: box-shadow 0.2s cubic-bezier(0.45, 0, 0.55, 1);
}

.about-info-item {
  margin-top: 20px;
}
.about-info-item li {
  font-size: 16px;
}
</style>
