<template>
  <footer class="footer section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6">
          <div class="widget">
            <h4 class="text-capitalize mb-4">Company</h4>
            <p>
              Envision Equity Group is a privately held
investment firm focused on acquisition of
hospitals, other healthcare, and
medical equipment,
products businesses.



            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="widget">
            <h4 class="text-capitalize mb-4">Quick Links</h4>

            <ul class="list-unstyled footer-menu lh-35">
              <li><a href="#">Team</a></li>
              <li><a href="#">Vision & Mission</a></li>
              <li><a href="#">Medical & Health</a></li>
              <li><a href="#">Manufacturing & Engineering</a></li>
              <li><a href="#">Contact Us</a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 ml-auto col-sm-6">
          <div class="widget">
            <div class="logo mb-4">
              <h3>Envision Equity Group</h3>
            </div>
            <p>
              3838 Oak Lawn Ave <br />
              Ste 1000 <br />
              Dallas TX 75219
            </p>
          </div>
        </div>
      </div>

      <div class="footer-btm pt-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="copyright">
              &copy; {{(new Date()).getFullYear() }} by
              <span class="text-color">Envision Equity Group. </span> All rights reserved.

            </div>
          </div>
          <div class="col-lg-6 text-left text-lg-right">
             Developed by
              <a href="https://askzeeshan.com/" target="_blank"></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
